import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/sys/rule/page',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(id) {
  return http({
    url: '/admin/sys/rule/detail',
    method: 'get',
    params: { id }
  })
}
// 删除
export function delAPI(id) {
  return http({
    url: '/admin/sys/rule/delete',
    method: 'post',
    data: { id }
  })
}
// 状态
export function changeStatusAPI(data) {
  return http({
    url: '/admin/sys/rule/editStatus',
    method: 'post',
    data
  })
}
// add
export function addAPI(data) {
  return http({
    url: '/admin/sys/rule/add',
    method: 'post',
    data
  })
}
// del
export function editAPI(data) {
  return http({
    url: '/admin/sys/rule/edit',
    method: 'post',
    data
  })
}

// 策略列表
export function getStrategyListAPI(params) {
  return http({
    url: '/admin/sys/ruleStrategy/list',
    method: 'get',
    params
  })
}
// 策略详情
export function getStrategyDetailAPI(id) {
  return http({
    url: '/admin/sys/ruleStrategy/detail',
    method: 'get',
    params: { id }
  })
}
